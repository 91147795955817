var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-right rounded-3"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Dashboard")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Attendances")])])]),_c('div',{staticClass:"content-body",staticStyle:{"min-height":"calc(100vh - 135px)"},attrs:{"id":"fullHeight"}},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col text-end"},[(_vm.$store.state.permittedActions.includes(33))?_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":{ name: 'add-manually' }}},[_vm._v("Add Manually")]):_vm._e()],1)]),_c('div',{staticClass:"t-layout"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(this.$store.state.loader)?_c('div',[_c('loader',{attrs:{"object":"#f74b3d","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","disableScrolling":"false","name":"spinning"}})],1):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('flash-message',{staticClass:"myCustomClass"})],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('b-form-input',{attrs:{"type":"search","placeholder":"Search data"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"col-md-9 tx-right"},[_c('div',{staticClass:"page-number-input custom-box"},[_c('label',[_vm._v("Go to Page:")]),_c('b-form-input',{staticClass:"page-number",attrs:{"type":"number","min":"1","max":_vm.attandanceData.data.length > 0
                          ? Math.ceil(_vm.attandanceData.data.length / _vm.perPage)
                          : 1},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"pagination-wrapper custom-box"},[_c('b-pagination',{attrs:{"total-rows":_vm.attandanceData.data.length,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":function($event){return _vm.updatePerPage()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('b-table',{staticClass:"table table-custom",attrs:{"items":_vm.attandanceData.data,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"filter":_vm.filter,"sort-compare":_vm.mySortCompare,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"sticky-header":""},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
                          var label = ref.label;
                          var ref_field = ref.field;
                          var key = ref_field.key;
                          var sortable = ref_field.sortable;
return [_vm._v(" "+_vm._s(label)+" "),(sortable)?[[(_vm.sortBy !== key)?_c('b-img',{staticStyle:{"width":"0.65rem","height":"1rem"},attrs:{"src":_vm.sortIconNeutral}}):(_vm.sortDesc)?_c('span',{attrs:{"title":"Sort By ascending"}},[_c('b-img',{staticStyle:{"width":"0.65rem","height":"1rem"},attrs:{"src":_vm.sortIconAsc}})],1):_c('span',{attrs:{"title":"Sort By descending"}},[_c('b-img',{staticStyle:{"width":"0.65rem","height":"1rem"},attrs:{"src":_vm.sortIconDesc}})],1)]]:_vm._e()]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.status_timein)+" / "+_vm._s(data.item.status_timeout)+" ")]}},{key:"cell(timein)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatTime(data.item.timein))+" ")]}},{key:"cell(timeout)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.timeout != null || (data.item.timeout != null) != "" ? _vm.formatTime(data.item.timeout) : "")+" ")]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"d-flex justify-content-md-around"},[(_vm.$store.state.permittedActions.includes(31))?_c('a',{staticClass:"button button1",attrs:{"title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.setPageNumber(data.item.id)}}},[_c('i',{staticClass:"icon-pencil"})]):_vm._e(),(_vm.$store.state.permittedActions.includes(32))?_c('a',{staticClass:"button button1",attrs:{"title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteAttendance(data.item.id)}}},[_c('i',{staticClass:"icon-bin"})]):_vm._e()])])]}}])})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-5"},[_c('h2',{staticClass:"title2"},[_vm._v("Attendances")])])}]

export { render, staticRenderFns }